import React, { useEffect, useState } from 'react';
import { routes } from './routes';
import './desktopLayout.css'
import Keycloak from 'keycloak-js';

const Layout = (props) => {

  const [indexRoutes, setindexRoutes] = useState()

  const [menuClick, setmenuClick] = useState(window.location.href);

  useEffect(() => {
    const handler = () => {
      setmenuClick((prev) => {
        const newHash = window.location.href;
        if (prev !== newHash) {
          return newHash;
        }
        return prev;
      });
    };
    window.addEventListener("hashchange", handler);
    return () => {
      window.removeEventListener("hashchange", handler);
    };
  }, []);

  const url = menuClick.split('/')
  const endPoint = url[url.length - 1]

  useEffect(() => {
    routes.map((route, index) => {
      route.routes.map((link) => {
        if (link.url == `/${endPoint}`) {
          setindexRoutes(index)
          return;
        }
      })
    })
  }, [])

  return (
    <div className='app-container'>
      <div className='row mx-0 h-100'>
        <nav className='col-md-2 px-0 col-12'>
          <div className=' menu'>
            <div className='logo-container d-flex flex-column mb-4'>
              <div className='my-4'>
                <img
                  src='/image/Cajero-logo.svg'
                  width='150'
                />
              </div>
            </div>

            <div className='menulist'>
              {/* {routes &&
                routes.map((menu) =>
                  <MenuLink
                    key={menu.routes[0].url}
                    url={menu.routes[0].url}
                    name={menu.name}
                    click={routes[indexRoutes] && menu.name == routes[indexRoutes].name}
                  />
                )
              } */}
              <div className=''>
                <div className='my-4'>
                  <img
                    src='/image/CeluIcon.png'
                    width='150'
                  />
                </div>
              </div>
            </div>

          </div>
        </nav>

        <div className='col-md-10 pt-4 px-2 col-12 '>
          <div className='username text-right font-weight-bold text-capitalize pr-4'>Hola |&nbsp;
            <a
              onClick={(e) => {
                logout()
              }}
            >Salir</a>

          </div>
          <div className='header px-4'>
            <div className='ml-5 title'>
              <h4>{routes[indexRoutes] && routes[indexRoutes].name.toUpperCase()}</h4>
            </div>
            <ul className="nav ml-5">
              {routes[indexRoutes] && routes[indexRoutes].routes.map((ruta) => {
                let isActive;
                `/${endPoint}` == ruta.url ? isActive = 'active' : isActive = ''
                return (
                  <li className="nav-item" key={ruta.url}>
                    <a className={`nav-link ${isActive}`} href={ruta.url} >{ruta.name}</a>
                  </li>
                )
              })}

            </ul>
          </div>
          <div className='mt-1 px-3 py-1 col-md-10 content col-12'>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
}

const logout = () => {
  let keycloak = Keycloak('/../../keycloak.json');
  keycloak.init({ checkLoginIframe: false, promiseType: 'native' }).success((auth) => {
    keycloak.logout({ redirectUri: "https://admin.cajero.co/" }).then((success) => {
      console.log("--> log: logout success ", success);
    }).catch((error) => {
      console.log("--> log: logout error ", error);
    });
  }).error(() => {
    console.error("Authenticated Failed");
  });
}
export default Layout;

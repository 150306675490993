const BASE_URL = 'https://api.site.cajero.co/api/v1/gateway/sendpaymentorderapibymerchant';

const PaymentLink = async (dataObj) => {
  try {
    const response = await fetch(`${BASE_URL}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dataObj),
    });

    const body = await response.json();
    return body;

  } catch (error) {
    console.log('ERROR GENERATE LOTE', error);
  }

  return null;
};

export default PaymentLink;
import React, { useState } from 'react';
import { UploadField } from '../../components/UploadField';
import * as XLSX from 'xlsx';
import DataTable from 'react-data-table-component';
import Button from '../../lib/ui/button/Button';
import DownloadForm from './DownloadForm';
import PaymentLinkBusiness from '../../business/PaymentLinkBusiness';
import loading from '../../lib/ui/loading';
import GenericModal from "../../components/modalConfirm/GenericModal";


function PaymentLink() {
  const [data, setData] = useState([]);
  const [response, setResponse] = useState([]);
  const [showModalAmount, setShowModalAmount] = useState(false);
  const [showModalError, setShowModalError] = useState(false);

  const columnsData = [
    { name: "Merchant", selector: "merchantCode" },
    { name: "Amount", selector: "amount" },
    { name: "Description", selector: "description" },
  ];

  const columnsPaymentLinkBusiness = [
    { name: "Merchant", selector: "merchantCode" },
    { name: "Amount", selector: "amount" },
    { name: "Description", selector: "description" },
    { name: "Referencia", selector: "referencia" },
    { name: "Link", selector: "link" },
  ];

  const downloadForm = [
    { merchantCode: "", amount: "", description: "" },
  ];

  const handleFileUpload = file => {
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      processData(data);
    };
    reader.readAsBinaryString(file);
  }

  const processData = dataString => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"')
              d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"')
              d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }
        if (Object.values(obj).filter(x => x).length > 0) {
          list.push(obj);
        }
      }
    }

    const columns = headers.map(c => ({ name: c, selector: c }));
    columns.push({ name: "success", selector: "success" })
    setData(list);
  }

  return (
    <div className='mt-4 text-left'>
      {(showModalAmount || showModalError) && (
        <GenericModal>
          <div className="mt-2" justify="center">
            {showModalAmount
              ? <p>Recuerda que el valor mínimo para generar un link de pago debe ser $3.500</p>
              : <p>Un link de pago no pudo ser generado, revisa los datos y vuelve a intentar</p>}
          </div>

          <div align='center'>
            <button
              type='button'
              className='btn btn-secondary mt-3'
              onClick={() => {
                setShowModalAmount(false);
                setShowModalError(false);
              }}
            >
              Cerrar
            </button>
          </div>

        </GenericModal>
      )}
      <div className='mb-4 '>
        <h3 className='title d-flex justify-content-start'>LINK DE PAGO</h3>
        <div className='mt-5'>
          Sigue las siguientes instrucciones:
        </div>
        <div>
          1. Sigue el siguiente ejemplo de cómo subir el archivo csv o xlsx:
        </div>
        <div className='logo-container d-flex flex-column'>
          <div className='my-4'>
            <img
              src={require('../../assets/img/PaymentLink.PNG')}
              width='400'
            />
          </div>
        </div>
      </div>

      <div className='mb-4'>
        <div className='mb-2'>
          2. Descarga el archivo:
        </div>
        <div className='d-flex justify-content-start'>
          <Button
            xsmall
            height='38px'
            className=''
            text='Descargar archivo ejemplo'
            onClick={() => {
              DownloadForm(downloadForm);
            }}
          />
        </div>
      </div>

      <div className='mb-4'>
        <div className='mb-2'>
          3. Sube el archivo con toda la información como en el ejemplo (ten en cuenta que el valor mínimo para generar un link de pago debe ser $3.500):
        </div>

        {data.length === 0 &&
          <div className='d-flex justify-content-start'>
            <UploadField
              acceptType='.csv, .xlsx'
              setFileInfo={(fileInfo) => handleFileUpload(fileInfo.fileContent)}
            />
          </div>
        }

        {(data.length > 0 && response.length === 0) &&
          <div className='d-flex justify-content-start'>
            <div className='w-75'>
              <DataTable
                pagination
                highlightOnHover
                columns={columnsData}
                data={data}
              />
            </div>
          </div>
        }

        {(data.length > 0 && response.length > 0) &&
          <div className='d-flex justify-content-start'>
            <div className='w-75'>
              <DataTable
                pagination
                highlightOnHover
                columns={columnsPaymentLinkBusiness}
                data={data}
              />
            </div>
          </div>
        }
      </div>

      <div className='mb-4'>
        {response.length === 0 &&
          <div>
            <div className='mb-2'>
              4. Oprime el botón “Enviar” para generar los links de pago.
            </div>

            <Button
              xsmall
              height='38px'
              className=''
              text='Enviar'
              onClick={async () => {
                if (data.length > 0) {
                  loading.showLoadingScreen();
                  const dataProcess = [];
                  let i = 0;
                  for (const obj of data) {
                    if (obj.amount >= 3500) {
                      const linkObj = await PaymentLinkBusiness(obj);
                      if (linkObj.errorCode === undefined) {
                        data[i].referencia = linkObj.data.code;
                        data[i].link = "https://cajero.co/pagofacil/" + linkObj.data.id;
                        dataProcess.push({ linkObj });
                      } else {
                        setShowModalError(true);
                      }
                    } else {
                      setShowModalAmount(true);
                    }
                    i += 1;
                  }
                  setResponse(dataProcess);
                  loading.hideLoadingScreen();
                }
              }}
            />
          </div>
        }

        {response.length > 0 &&
          <div>
            <Button
              xsmall
              height='38px'
              className=''
              text='Descargar links de pago'
              onClick={() => { DownloadForm(data); }}
            />
          </div>
        }
      </div>
      <style>{`
        .title {
          color: #5916bc;
        }
      `}</style>
    </div>
  )
}

export default PaymentLink;
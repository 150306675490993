import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { COLOR_MORADO_CAJERO, COLOR_ROJO_CAJERO, COLOR_VERDE_CAJERO, LIGHT_PURPLE } from '../colors/colors';

const Button = (props) => {
  const {
    text,
    large,
    small,
    xsmall,
    outline,
    icon,
    color,
    isLoading,
    className,
    height,
  } = props;

  let {
    disabled
  } = props;

  let buttonProps = Object.assign({}, props);

  const csProps = buttonProps.className || null;

  delete buttonProps.text;
  delete buttonProps.large;
  delete buttonProps.small;
  delete buttonProps.className;
  delete buttonProps.outline;
  delete buttonProps.icon;
  delete buttonProps.color;
  delete buttonProps.disabled;
  delete buttonProps.isLoading;
  delete buttonProps.xsmall;
  if (isLoading ) {
    disabled = true;
  }


  return (
    <React.Fragment>
      <button
        className={`button 
          ${large && 'button-lg'}
          ${small && 'button-sm'}
          ${outline && 'button-outline'}
          ${color === 'secondary' && 'button-red'}
          ${color === 'green' && 'button-green'}
          ${color === 'white' && 'button-white'}
          ${xsmall && 'button-xs'}
          ${className && className}`}
        disabled={disabled}
        {...buttonProps}
      >
        {text}
        {(icon || isLoading) &&
          <span className={`button-icon ${icon || 'fa fa-spinner fa-spin ml-1'}`} />
        }
      </button>

      <style>{`
        button:disabled,
        button[disabled]{
          border: 1px solid ${LIGHT_PURPLE};
          background-color: ${LIGHT_PURPLE};
        }
        
        .button-green:disabled,
        .button-green[disabled]{
          border: 1px solid #00D28A8F;
          background-color: #00D28A8F;
        }

        .button-white:disabled,
        .button-white[disabled]{
          border: 1.5px solid ${LIGHT_PURPLE};
          background-color: white;
        }

        .button {
          color:#fff;
          border: 1px solid ${COLOR_MORADO_CAJERO};
          background-color: ${COLOR_MORADO_CAJERO};
          border-radius: 5px;
          font-family: Font Awesome 5 Brands;
          font-weight:700;
          font-size: 18px;
          padding: 8px 25px;
          outline: none;
        }

        .button-icon {
          margin-right: 5px;
          margin-left: 5px;
        }

        .button-outline {
          background-color: #FFFFFF;
          color: ${COLOR_MORADO_CAJERO};
        }

        .button-outline:hover {
          background-color: ${COLOR_MORADO_CAJERO};
          color: #fff;
        }

        .button-red {
          background-color: ${COLOR_ROJO_CAJERO};
          border: 1px solid ${COLOR_ROJO_CAJERO};
        }

        .button-green {
          background-color: ${COLOR_VERDE_CAJERO};
          border: 1px solid ${COLOR_VERDE_CAJERO};
        }
        .button-white {
          background-color: white;
          border: 1.5px solid ${COLOR_MORADO_CAJERO};
          color:${COLOR_MORADO_CAJERO};
        }
        .button-sm {
          padding: 6px 25px !important;
          font-size: 16px;
        }
        .button-xs {
          padding: 6px 25px !important;
          font-size: 16px;
          height:${height ? height : '40px' };
        }
      `}</style>
    </React.Fragment>
  );
}

Button.propTypes = {
  icon: PropTypes.node,
  outline: PropTypes.bool,
  color: PropTypes.string,
  text: PropTypes.string.isRequired,
  large: PropTypes.bool,
  small: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  icon: null,
  color: 'primary',
  large: false,
  small: false,
  outline: false,
  isLoading: false,
  disabled: false,
  xsmall: false,
};

export default Button;
export const routes = [
  {
    name: "Link de pago",
    routes:
      [
        {
          url: '/paymentLink',
          name: 'Link de pago'
        },
      ]
  },
]
import { createBrowserHistory } from 'history';
import { createBrowserNavigation, mount, route } from 'navi';
import React, { Suspense, useState } from 'react';
import { Router, View } from 'react-navi';
import './App.scss';
import DesktopLayout from './components/WebDesktop/DesktopLayout';
import LoadingComponent from './lib/ui/loading/LoadingComponent';
import PaymentLink from './view/paymentLink/PaymentLink';
const history = createBrowserHistory();

function App() {
  const [finalRoutes, setFinalRoutes] = useState({
    '/': route({
      title: 'paymentLink',
      view: <PaymentLink />,
    }),
  })

  const routes =
    mount(finalRoutes)

  let navi = createBrowserNavigation({
    routes: routes,
    history: history
  })
  return (
    <div className="App">
      <LoadingComponent />
      <DesktopLayout titulo="Asignar merchantcode">
        <Router history={history} navigation={navi}>
          <Suspense fallback={null}>
            <View />
          </Suspense>
        </Router>
      </DesktopLayout>
    </div>
  );
}

export default App;

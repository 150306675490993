const COLOR_ROJO_CAJERO = '#FF5454';
const COLOR_GRIS = '#B1B1B1';
const COLOR_PURPURA = '#5916bc';
const COLOR_VERDE_CAJERO = '#00D28A';
const COLOR_NEGRO = '#434349';
const LIGHT_GRAY = '#959CB6';
const LIGHT_PURPLE = '#8353CB';
const COLOR_MORADO_CAJERO = '#5916bc';
const COLOR_MORADO_BTN = '#4E17BA';
const DARK_GREEN = '#0D9910';

export {
    COLOR_ROJO_CAJERO,
    COLOR_PURPURA,
    COLOR_VERDE_CAJERO,
    COLOR_NEGRO,
    LIGHT_GRAY,
    COLOR_GRIS,
    LIGHT_PURPLE,
    COLOR_MORADO_CAJERO,
    COLOR_MORADO_BTN,
    DARK_GREEN,
};
import React from "react";

function GenericModal(props) {

  const { title } = props;

  return (
    <React.Fragment>
      <div className="modal-container">
        <div className="modal-dialog className">
          <div className="modal-content box-radius box-shadow">
            <div className="modal-header d-flex justify-content-center">
              <h4 className="modal-title">{title}</h4>
            </div>
            <div className="ml-3 mr-3 mb-3">
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default GenericModal;

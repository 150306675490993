import * as XLSX from 'xlsx';

function DownloadForm(data) {

    const exportJS = (dataObj) => {
        const data = dataObj;
        const fields = Object.keys(data[0]);

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(data, { header: fields });

        XLSX.utils.book_append_sheet(wb, ws, "Link de pago");
        XLSX.writeFile(wb, "Link de pago.xlsx");
    };

    return (
        exportJS(data)
    );
}

export default DownloadForm;
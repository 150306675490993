import { faTimes, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

function UploadField(props) {
  const [fileInfo, setFileInfo] = useState();

  let handleFileLoad = (file) => {
    let infoTemp = {
      name: file[0].name,
      fileContent: file[0]
    };

    if (!props.multiple) {
      setFileInfo(infoTemp.name);
    }

    if (props.setFileInfo) {
      props.setFileInfo(infoTemp);
    }
  }

  return (
    <div
      className={`field-upload ${props.inputStyle}`}
    >
      <input
        type='file'
        name='file'
        id={`file-${props.fileName}`}
        accept={props.acceptType}
        className='inputfile'
        onChange={(element) => handleFileLoad(element.target.files)}
        onClick={(event) => {
          event.target.value = null
        }}
      />

      <label
        htmlFor={`file-${props.fileName}`}
        className='m-0'
      >
        {!fileInfo &&
          <FontAwesomeIcon className='icon mx-2' icon={faUpload} />
        }
        <div
          className='d-flex'
        >
          {fileInfo ?
            <div
              className='mx-2 file-name'
            >
              {fileInfo}
            </div>
            :
            <span
              className={`${props.identDownload && 'link-primary cursor-pointer'}`}
              onClick={() => {
                if (props.onDownload) {
                  props.onDownload();
                }
              }}
            >
              {props.fileName}
            </span>
          }
        </div>
      </label>

      {fileInfo &&
        <div
          className='cursor-pointer btn-delete-file'
          onClick={() => {
            setFileInfo();
            if (props.setFileInfo) {
              props.setFileInfo(null);
            }
          }}
        >
          <FontAwesomeIcon className='icon' icon={faTimes} />
        </div>
      }
    </div >
  )
}

UploadField.propTypes = {
  fileName: PropTypes.string,
  acceptType: PropTypes.string,
  multiple: PropTypes.bool
};

UploadField.defaultProps = {
  fileName: 'Subir Archivo',
  acceptType: '*.*',
  multiple: false
};

export { UploadField };